<template>
  <div class="about">
    <div id="myMap"></div>
    <el-button type="primary" size="mini" title="点击返回上一级" class="btn" @click="upside">{{ adName }}</el-button>
    <div class="point">江苏超敏科技有限公司</div>
  </div>
</template>
<script>
import { organizationQueryWithPage } from '@/util/http'
import { isPointInMultiPolygon } from '@/util/echartTool.js'
import jsonData from '@/util/chartJson/all.json'
let myChartMap = null //地图
let charTimer = null // 点位轮播
export default {
  name: 'about',
  data() {
    return {
      optionMap: {
        tooltip: {
          show: false,
        },
        geo: [
          {
            map: 'china',
            aspectScale: 1,
            zoom: 0.9,
            layoutCenter: ['50%', '50%'],
            layoutSize: '180%',
            show: true,
            roam: false,
            label: {
              show: false,
              color: '#fff',
            },
            itemStyle: {
              areaColor: {
                type: 'linear',
                x: 1200,
                y: 0,
                x2: 0,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: 'rgba(3,27,78,0.75)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(58,149,253,0.75)',
                  },
                ],
                global: true, // 缺省为 false
              },
              borderColor: '#c0f3fb',
              borderWidth: 0.8,
            },
            emphasis: {
              itemStyle: {
                show: false,
                color: '#fff',
                areaColor: 'rgba(0,254,233,0.6)',
              },
              label: {
                show: true,
                color: '#fff',
              },
            },
          },
          // 重影
          {
            type: 'map',
            map: 'china',
            zlevel: -1,
            aspectScale: 1,
            zoom: 0.9,
            layoutCenter: ['50%', '51%'],
            layoutSize: '180%',
            roam: false,
            silent: true,
            itemStyle: {
              borderWidth: 1,
              borderColor: 'rgba(58,149,253,0.8)',
              shadowColor: 'rgba(172, 122, 255,0.5)',
              shadowOffsetY: 5,
              shadowBlur: 15,
              areaColor: 'rgba(5,21,35,0.1)',
            },
          },
          {
            type: 'map',
            map: 'china',
            zlevel: -2,
            aspectScale: 1,
            zoom: 0.9,
            layoutCenter: ['50%', '52%'],
            layoutSize: '180%',
            roam: false,
            silent: true,
            itemStyle: {
              borderWidth: 1,
              borderColor: 'rgba(58,149,253,0.6)',
              shadowColor: 'rgba(65, 214, 255,0.6)',
              shadowOffsetY: 5,
              shadowBlur: 15,
              areaColor: 'rgba(5,21,35,0.1)',
            },
          },
          {
            type: 'map',
            map: 'china',
            zlevel: -3,
            aspectScale: 1,
            zoom: 0.9,
            layoutCenter: ['50%', '53%'],
            layoutSize: '180%',
            roam: false,
            silent: true,
            itemStyle: {
              borderWidth: 1,
              borderColor: 'rgba(58,149,253,0.4)',
              shadowColor: 'rgba(29, 111, 165,1)',
              shadowOffsetY: 15,
              shadowBlur: 10,
              areaColor: 'rgba(5,21,35,0.1)',
            },
          },
          {
            type: 'map',
            map: 'china',
            zlevel: -4,
            aspectScale: 1,
            zoom: 0.9,
            layoutCenter: ['50%', '54%'],
            layoutSize: '180%',
            roam: false,
            silent: true,
            itemStyle: {
              borderWidth: 5,
              borderColor: 'rgba(5,9,57,0.8)',
              shadowColor: 'rgba(29, 111, 165,0.8)',
              shadowOffsetY: 15,
              shadowBlur: 10,
              areaColor: 'rgba(5,21,35,0.1)',
            },
          },
        ],
        series: [
          {
            type: 'lines', // 飞线图
            zlevel: 2,
            effect: {
              show: true,
              period: 3, //箭头指向速度，值越小速度越快
              trailLength: 0.03, //特效尾迹长度[0,1]值越大，尾迹越长重
              symbol: 'arrow', //箭头图标
              symbolSize: 6, //图标大小
            },
            lineStyle: {
              color: '#EE5652',
              width: 1, //尾迹线条宽度
              opacity: 1, //尾迹线条透明度
              curveness: 0.3, //尾迹线条曲直度
            },
            data: [],
            symbol: ['none', 'circle'], //飞线起点终点点位样式
            symbolSize: 10, // 飞线起点终点点位大小\
          },
          {
            type: 'effectScatter',
            zlevel: 3,
            coordinateSystem: 'geo',
            emphasis: {
              label: {
                show: true,
                position: 'top',
                color: '#fff',
                formatter: '{b|{b}}',
              },
            },
            label: {
              show: false,
              position: 'top',
              color: '#fff',
            },
            data: [],
            symbol: 'circle',
            symbolSize: [20, 10],
            itemStyle: {
              color: 'orange',
              shadowBlur: 10,
              shadowColor: 'orange',
            },
            effectType: 'ripple',
            showEffectOn: 'render', //emphasis移入显示动画，render一开始显示动画
            rippleEffect: {
              scale: 5,
              brushType: 'stroke',
            },
          },
          {
            type: 'scatter',
            coordinateSystem: 'geo',
            zlevel: 4,
            label: {
              show: true,
              formatter: function (params) {
                return params.name
              },
              color: '#fff',
              fontSize: 14,
              align: 'center',
              opacity: 1,
            },
            emphasis: {
              show: true,
            },
            data: [],
            symbol: 'image://' + require('../../../public/home/point.png'),
            symbolSize: (e) => {
              return [e[2].name.length * 20, 50]
            },
            symbolOffset: [0, -40],
          },
        ],
      },
      level: 0,
      historyData: [],
      adName: '中国',
      resData: [],
    }
  },
  methods: {
    // 南沙诸岛以缩略图展示
    async formatJson(chinaGeoJson) {
      chinaGeoJson.features.forEach((v) => {
        if (v.properties && v.properties.name == '海南省') {
          v.geometry.coordinates = v.geometry.coordinates.slice(0, 1)
        }
      })
      // 过滤掉海南诸岛边界线
      chinaGeoJson.features = chinaGeoJson.features.filter((item) => item.properties.adcode !== '100000_JD')
      return chinaGeoJson
    },
    // 初始化
    async initChart() {
      let formatChinaGeoJson = await this.formatJson(require('@/util/chartJson/china.json'))
      // 鼠标移入点位的弹窗
      let rich = {
        b: {
          color: '#fff',
          backgroundColor: {
            image: require('../../../public/home/point.png'),
          },
          padding: [20, 30],
          fontSize: 14,
          align: 'center',
        },
      }
      this.optionMap.series[1].emphasis.label.rich = rich
      this.initEcharts(formatChinaGeoJson, 'china')
    },
    // 格式化点位飞线数据
    filterLines(currentData) {
      let dataLines = []
      let center = [120.537612, 31.276282]
      currentData.forEach((v) => {
        let lonLat = [v.value[0], v.value[1]]
        dataLines.push({
          coords: [lonLat, center],
        })
      })
      return dataLines
    },
    // 点位颜色高亮
    highLightPoint(currentData, index) {
      currentData.forEach((v, key) => {
        let flag = key == index ? 'aqua' : 'orange'
        v.itemStyle = {
          color: flag,
          shadowColor: flag,
        }
      })
    },
    // 格式图表
    async formatChart(geoJson, name) {
      // 过滤出当前地图的点位
      let currentData = this.resData.filter((v) => {
        return isPointInMultiPolygon([v.value[0], v.value[1]], geoJson.features)
      })
      this.adName = name == 'china' ? '中国' : name
      this.$echarts.registerMap(name, geoJson)
      this.optionMap.geo.forEach((v) => {
        v.map = name
        v.layoutSize = name == 'china' ? '180%' : '100%'
      })
      this.optionMap.series[0].data = this.filterLines(currentData)
      this.optionMap.series[1].data = currentData
      this.optionMap.series[2].data = currentData.slice(0, 1)
      // 点位颜色高亮
      this.highLightPoint(currentData, 0)
      myChartMap.clear()
      myChartMap.setOption(this.optionMap)
      if (charTimer) {
        clearInterval(charTimer)
        charTimer = null
      }
      // 点位大于2个才循环轮播
      if (currentData.length > 1) {
        let i = 0
        charTimer = setInterval(() => {
          i++
          if (i >= currentData.length) i = 0
          this.optionMap.series[2].data = currentData.slice(i, i + 1)
          this.highLightPoint(currentData, i)
          myChartMap.setOption(this.optionMap)
        }, 1000 * 3)
      }
      let toolipDom = document.getElementsByClassName('point')[0]
      myChartMap.on('mousemove', (params) => {
        // 获取鼠标相对于飞线图的坐标
        let mouseX = params.event.offsetX
        let mouseY = params.event.offsetY
        // 获取弹窗dom宽高
        let domWidth = toolipDom.clientWidth
        let domeight = toolipDom.clientHeight
        // 将鼠标坐标转换为飞线图上的经纬度坐标
        let geoCoord = myChartMap.convertFromPixel({ seriesIndex: 0 }, [mouseX, mouseY])
        // 获取飞线图的数据
        let data = myChartMap.getOption().series[0].data
        if (data.length) {
          let point = data[0].coords[1]
          if (Math.abs(point[0] - geoCoord[0]) < 0.04 && Math.abs(point[1] - geoCoord[1]) < 0.04) {
            toolipDom.style.display = 'block'
            toolipDom.style.top = mouseY - domeight - 10 + 'px'
            toolipDom.style.left = mouseX - domWidth / 2 + 'px'
          } else {
            toolipDom.style.display = 'none'
          }
        }
      })
      myChartMap.on('mouseout', () => {
        toolipDom.style.display = 'none'
      })
    },
    //echarts绘图
    initEcharts(geoJson, name) {
      this.formatChart(geoJson, name)
      myChartMap.hideLoading()
      window.addEventListener('resize', this.resizeFun)
      this.level++
      this.historyData.push({ geoJson, name })
      myChartMap.off('click')
      myChartMap.on('click', (params) => {
        // 点击的是当前地图
        if (params.componentType != 'geo') return
        // 暂时开放江苏省(32xxxxxx)的地图下钻, 其他省待开放
        let adcode = jsonData.filter((a) => a.name == params.name)[0].adcode
        if (adcode.toString().slice(0, 2) != '32') return
        // 下钻
        let chartJson = require(`@/util/chartJson/${params.name}${adcode}.json`)
        this.initEcharts(chartJson, params.name)
      })
    },
    resizeFun() {
      myChartMap.resize()
    },
    // 返回上一级
    upside() {
      if (this.historyData.length <= 1) return
      this.level--
      this.historyData.pop()
      let upData = this.historyData[this.level - 1]
      this.formatChart(upData.geoJson, upData.name)
    },
    // 获取机构点位
    async getOrg() {
      let users = this.$store.state.user
      let params = {
        areaId: users.areaId,
        id: users.organizationId,
        pageSize: 1000,
      }
      this.resData = []
      await organizationQueryWithPage(params).then((data) => {
        if (data) {
          data.result.records.forEach((v) => {
            let dataObj = {
              name: v.name,
              value: [Number(v.longitude), Number(v.latitude), v],
            }
            this.resData.push(dataObj)
          })
        }
      })
    },
  },
  watch: {
    '$store.state.isCollapse': {
      handler(newVal, oldVal) {
        setTimeout(() => {
          myChartMap.resize()
        }, 500)
      },
    },
  },
  mounted() {
    myChartMap = this.$echarts.init(document.getElementById('myMap'))
    myChartMap.showLoading({
      text: '加载中...',
      textColor: '#fff',
      color: '#fff',
      maskColor: 'rgba(0 ,0 ,0 ,0.3 )',
      zlevel: 0,
    })
    this.getOrg().then(() => {
      this.initChart()
    })
  },
  destroyed() {
    if (myChartMap) {
      myChartMap.clear()
      myChartMap = null
    }
    if (charTimer) {
      clearInterval(charTimer)
      charTimer = null
    }
    window.removeEventListener('resize', this.resizeFun)
  },
}
</script>
<style lang="less">
.about {
  height: 100%;
  position: relative;
}
#myMap {
  width: 100%;
  height: 100%;
}
.btn {
  position: absolute;
  top: 0px;
  left: 10px;
}
.point {
  display: none;
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 15px 20px;
  background: url('../../../public/home/point.png') no-repeat;
  background-size: 100% 100%;
  font-size: 16px;
  color: aqua;
}
</style>
